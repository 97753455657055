import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { GlobalSpinnerComponent } from "./global-spinner/global-spinner.component";
import { GlobalSpinnerInterceptor } from './global-spinner/global-spinner.interceptor';
import { ToastsContainerComponent } from "./toasts-container/toasts-container.component";
import { EfDrawerComponent } from './ef-drawer/ef-drawer.component';
import { MaterialModule } from './material-module';
import { DataTableComponent } from './data-table/data-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EfPaginatorComponent } from './ef-paginator/ef-paginator.component';
import { EfMultiselectComponent } from './ef-multiselect/ef-multiselect.component';
import { B2bSelectComponent } from "./b2b-select/b2b-select.component";
import { B2bDatepickerComponent } from './b2b-datepicker/b2b-datepicker.component';

@NgModule({
    declarations: [
        GlobalSpinnerComponent,
        ToastsContainerComponent,
        EfDrawerComponent,
        DataTableComponent,
        EfPaginatorComponent,
        EfMultiselectComponent,
        B2bSelectComponent,
        B2bDatepickerComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        MatChipsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        ClipboardModule
    ],
    exports: [
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        MaterialModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        ClipboardModule,
        GlobalSpinnerComponent,
        ToastsContainerComponent,
        EfDrawerComponent,
        DataTableComponent,
        MatDialogModule,
        EfPaginatorComponent,
        EfMultiselectComponent,
        B2bSelectComponent,
        B2bDatepickerComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GlobalSpinnerInterceptor, multi: true }
    ],
})
export class SharedModule { }