import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-ef-multiselect',
    templateUrl: './ef-multiselect.component.html',
    styleUrls: ['./ef-multiselect.component.scss']
})
export class EfMultiselectComponent {

    @Input() selectedOptions: any;
    @Input() allOptions: any;
    @Input() config: any = {
        cross: false
    };

    @Output() selectOption = new EventEmitter();
    @Output() onRemove = new EventEmitter();


    selectAction(option) {
        // console.log("HEllo")
        this.selectOption.emit(option)
    }

    onClose(opt: any) {
        this.onRemove.emit(opt);
    }

}
