import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-b2b-datepicker',
    templateUrl: './b2b-datepicker.component.html',
    styleUrls: ['./b2b-datepicker.component.scss']
})
export class B2bDatepickerComponent {
    @Input() value: string = "";
    @Output() onDateChange = new EventEmitter();
    filterDate = (date: Date): boolean => {
        return date <= new Date();
      }
    dateChange(ev: any) {
        // console.log(ev.value);
        this.onDateChange.emit(ev.value);
    }
}
