import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-ef-paginator',
    templateUrl: './ef-paginator.component.html',
    styleUrls: ['./ef-paginator.component.scss']
})
export class EfPaginatorComponent {
    @Input() currentPage: any;
    @Input() pages: any;
    @Output() onPageChange = new EventEmitter();

    changePage(page) {
        console.log(page);
        console.log(this.currentPage);
        this.onPageChange.emit(this.currentPage);
    }

    goToPreviousPage(){
        this.currentPage = this.currentPage - 1;
        this.onPageChange.emit(this.currentPage);
    }
    goToNextPage(){
        this.currentPage = this.currentPage + 1;
        this.onPageChange.emit(this.currentPage);
    }
}
